import Vue from 'vue'
import VueRouter from 'vue-router'

const index = () => import('../views/index/index');
const fankui = () => import('../views/index/fankui');
const wzCont = () => import('../views/wenzhang/wzCont');
const userCont = () => import('../views/wenzhang/userCont');
const gonggao = () => import('../views/gonggao/gonggao');
const ggList = () => import('../views/gonggao/ggList');
const user = () => import('../views/user/user');
const people = () => import('../views/user/people');
const order = () => import('../views/user/order');
const shoucang = () => import('../views/user/shoucang');
const addressCont = () => import('../views/user/address');
const orderCont = () => import('../views/user/orderCont');
const pay = () => import('../views/shop/pay');
const guanzhu = () => import('../views/user/guanzhu');
const orderPl = () => import('../views/user/orderPl');
const login = () => import('../views/login/login');
const mima = () => import('../views/login/mima');
const shop = () => import('../views/shop/shop');
const pinglun = () => import('../views/shop/pinglun');
const tuikuan = () => import('../views/shop/tuikuan');
const shopCont = () => import('../views/shop/shopCont');
const shopList = () => import('../views/shop/shopList');
const cart = () => import('../views/shop/cart');

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: '/index'
  },
  {
    path: '/index',
    component: index,
    name: index,
    meta: { hidden: true, title: "", keepAlive: true },
  },
  {
    path: '/fankui',
    component: fankui,
    name: fankui,
    meta: { hidden: true, title: "", keepAlive: true },
  },
  {
    path: '/wzCont',
    component: wzCont,
    name: wzCont,
    meta: { hidden: true, title: "", keepAlive: false }
  },
  {
    path: '/userCont',
    component: userCont,
    name: userCont,
    meta: { hidden: true, title: "", keepAlive: false }
  },
  {
    path: '/gonggao',
    component: gonggao,
    name: gonggao,
    meta: { hidden: true, title: "", keepAlive: false }
  },
  {
    path: '/ggList',
    component: ggList,
    name: ggList,
    meta: { hidden: true, title: "", keepAlive: true }
  },
  {
    path: '/shop',
    component: shop,
    name: shop,
    meta: { hidden: true, title: "", keepAlive: true }
  },
  {
    path: '/shopCont',
    component: shopCont,
    name: shopCont,
    meta: { hidden: true, title: "", keepAlive: false }
  },
  {
    path: '/shopList',
    component: shopList,
    name: shopList,
    meta: { hidden: true, title: "", keepAlive: true }
  },
  {
    path: '/cart',
    component: cart,
    name: cart,
    meta: { hidden: true, title: "", keepAlive: false }
  },
  {
    path: '/login',
    component: login,
    name: login,
    meta: { hidden: false, title: "", keepAlive: true }
  },
  {
    path: '/mima',
    component: mima,
    name: mima,
    meta: { hidden: false, title: "", keepAlive: true }
  },
  {
    path: '/user',
    component: user,
    name: user,
    meta: { hidden: true, title: "", keepAlive: true },
    children: [
      {
        path: 'people',
        component: people,
        meta: { hidden: true, title: "", keepAlive: false },
      },
      {
        path: 'order',
        component: order,
        meta: { hidden: true, title: "", keepAlive: false },
      },
      {
        path: 'shoucang',
        component: shoucang,
        meta: { hidden: true, title: "", keepAlive: false },
      },
      {
        path: 'addressCont',
        component: addressCont,
        meta: { hidden: true, title: "", keepAlive: false },
      },
      {
        path: 'orderPl',
        component: orderPl,
        meta: { hidden: true, title: "", keepAlive: false },
      },
    ],
  },
  {
    path: '/tuikuan',
    component: tuikuan,
    name: tuikuan,
    meta: { hidden: true, title: "", keepAlive: false }
  },
  {
    path: '/pinglun',
    component: pinglun,
    name: pinglun,
    meta: { hidden: true, title: "", keepAlive: false }
  },
  {
    path: '/orderCont',
    component: orderCont,
    name: orderCont,
    meta: { hidden: true, title: "", keepAlive: false }
  },
  {
    path: '/pay',
    component: pay,
    name: pay,
    meta: { hidden: false, title: "", keepAlive: false }
  },
  {
    path: '/guanzhu',
    component: guanzhu,
    name: guanzhu,
    meta: { hidden: true, title: "", keepAlive: false }
  },
]

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'hash',
  // base: process.env.BASE_URL,
  base: '/chuantian',
  routes,
  scrollBehavior(to, from, saveTop) {
    if (saveTop) {
      return saveTop;
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
