<template>
  <div>
    <div class="h280" v-if="height < pageHeight - 260"></div>
    <div
      class="bottomBox"
      ref="bottomBox"
      :class="height < pageHeight - 260 ? 'guding' : ''"
    >
      <div class="content">
        <div class="box">
          <div class="text" style="padding-bottom: 10px">
            浙江川田智能科技有限公司 ©2024 版权所有
          </div>
          <div class="name">川田用户社区</div>
          <div class="title">联系我们</div>
          <div class="txt">商城服务: 18767647976</div>
          <div class="txt">设备维修: 18767422026</div>
          <div class="text1" style="margin-top: 20px">地址：</div>
          <div class="text">中国 浙江省台州市台州湾新区聚英路2077号</div>
        </div>
        <div class="box1">
          <div class="boxs">
            <div class="txt">
              <a title="模板机产品" href="https://suzkj.com/chuantian/shop">产品中心</a>
            </div>
            <div class="shu"></div>
            <div class="txt">
              <a title="川田用户社区" href="https://suzkj.com/chuantian/index"
                >论坛讨论</a
              >
            </div>
            <div class="shu"></div>
            <div title="川田用户社区问题反馈" class="txt">
              <a href="https://suzkj.com/chuantian/fankui">提交反馈</a>
            </div>
          </div>
          <div class="cont">
            <div class="texts">
              <div class="txt" v-for="item in list" @click="cpJump(item.id)">
                {{ item.name }}
              </div>
            </div>
            <!-- <div class="texts">
              <div class="txt">服务标准</div>
              <div class="txt">服务联络</div>
              <div class="txt">维修流程</div>
              <div class="txt">下载中心</div>
              <div class="txt">售后支持</div>
            </div> -->
          </div>
        </div>
        <div class="box2">
          <div class="img">
            <img src="../../assets/img/ewm1.png" alt="" />
          </div>
          <div class="name">公众号</div>
          <div class="text">视频咨询一应俱全</div>
        </div>
        <div class="box2">
          <div class="img">
            <img src="../../assets/img/ewm1.png" alt="" />
          </div>
          <div class="name">小程序</div>
          <div class="text">在线产品对比</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      height: 0,
      pageHeight: 0,
    };
  },
  props: ["yemian"],
  watch: {
    yemian(val) {
      this.$nextTick(() => {
        this.height = 0;
        this.pageHeight = 0;
        // DOM更新完成后执行的代码
        setTimeout(() => {
          let height = this.$refs.bottomBox.getBoundingClientRect().top;
          this.height = height;
          this.pageHeight =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight;
        }, 500);
      });
    },
  },
  mounted() {
    if (this.list.length == 0) {
      this.get();
    }
  },
  activated() {},
  methods: {
    get() {
      this.$get("/web/part_gets", {
        pageNo: 1,
        pageSize: 6,
      }).then((res) => {
        this.judge = true;
        if (res.data) {
          this.list = res.data;
        }
      });
    },
    fkJump() {
      this.$router.push({
        path: "/fankui",
      });
    },
    ltJump() {
      this.$router.push({
        path: "/index",
      });
    },
    cpListJump() {
      this.$router.push({
        path: "/shop",
      });
    },
    cpJump(id) {
      this.$router.push({
        path: "/shopCont",
        query: {
          id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.bottomBox {
  background-color: rgba(33, 33, 33, 1);
  // width: 100vw;
  border-top: 2px solid #4ab344;
  padding-bottom: 50px;
  box-sizing: border-box;
  margin-top: 40px;
  .content {
    width: 1440px;
    margin: auto;
    padding-top: 15px;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    .box {
      width: 430px;
      color: rgba(169, 169, 169, 1);
      .text {
        font-size: 12px;
      }
      .name {
        font-size: 30px;
        font-family: fontface__思源黑体Heavy__19__1528813601190;
        color: rgb(238, 21, 5);
        font-weight: 600;
      }
      .title {
        font-size: 18px;
        color: rgb(219, 219, 219);
        font-family: 微软雅黑;
        margin-top: 5px;
      }
      .txt {
        color: #a9a9a9;
        font-size: 14px;
        margin-top: 1px;
      }
      .text1 {
        font-weight: 600;
        font-size: 12px;
        margin-top: 2px;
      }
    }
    .box1 {
      width: 450px;
      .boxs {
        display: flex;
        align-items: center;
        .txt {
          font-size: 16px;
          color: rgb(155, 155, 155);
          font-family: 微软雅黑;
          cursor: pointer;
        }
        .shu {
          background: rgb(155, 155, 155);
          width: 2px;
          height: 15px;
          margin: 0 25px;
        }
      }
      .cont {
        margin-top: 30px;
        .texts {
          float: left;
          margin-right: 160px;
          .txt {
            font-size: 12px;
            color: rgb(155, 155, 155);
            font-family: 微软雅黑;
            margin-bottom: 5px;
            cursor: pointer;
          }
          .txt:hover {
            color: #e40b0b;
          }
        }
        .texts:last-child {
          margin-right: 0;
        }
      }
    }
    .box2 {
      width: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .img {
        width: 90px;
        img {
          width: 90px;
          height: 90px;
        }
      }
      .name {
        font-size: 16px;
        color: rgb(219, 219, 219);
        font-family: 微软雅黑;
        margin-top: 10px;
        cursor: pointer;
      }
      .name:hover {
        color: #e9120b;
      }
      .text {
        margin-top: 5px;
        color: #dedede;
      }
    }
  }
}
.h280 {
  height: 280px;
}
.guding {
  position: fixed;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
}
</style>
